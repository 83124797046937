const md5 = require("md5");
import SlotJadwalType from "../constants/slot-jadwal-type-enum";
import DayEnum from "../constants/day-name-enum"
import MaxMinDate from "../constants/date-range-enum"
export default class RenderDataFullCalendar {
    changeColorBorderSlot2 (idJadwal) {
        let hexMd5 = this.getMd5(idJadwal);
        return hexMd5.slice(0, 6)
    }

    getMd5(str) {
        return md5(str);
    }

    splitingReservation (data, time) {
        return new Promise((resolve) => {
            const indexNameofTime = moment(time).day()
            const nameDayOfTime = DayEnum.DAY[indexNameofTime]
            const jadwalInThisTime = data.filter(item => 
                (item.jadwal.hari === nameDayOfTime) && 
                (moment(time).isBetween(item.jadwal.tanggalMulai ? item.jadwal.tanggalMulai : MaxMinDate.MIN_DATE,
                                        item.jadwal.tanggalSelesai ? item.jadwal.tanggalSelesai : MaxMinDate.MAX_DATE,
                                        null,
                                        []
                    ))
            )
            resolve(jadwalInThisTime)
        })
    }

    getSlotInJadwals (data) {
        return new Promise((resolve) => {
            let allSlot = []
            data.forEach(item => {
                if (item.slotJadwals && item.slotJadwals.length > 0) {
                    allSlot = item.slotJadwals
                }
            });
            resolve(allSlot)
        })
    }

    getDokterByJadwal (listDokter,date) {
        return new Promise ((resolve, reject ) => {
            try {
                const indexNameofTime = moment(date).day()
                const nameDayOfTime = DayEnum.DAY[indexNameofTime]
                let List = []
                if (listDokter && listDokter.length > 0) {
                    listDokter.forEach(item => {
                        if (item.jadwals && item.jadwals.length > 0) {
                            item.jadwals.forEach(day => {
                                if ( day.hari === nameDayOfTime ) {
                                    if (day.jam && day.jam.length > 0) {
                                        const jadwalInThisTime = day.jam.filter(item => 
                                            (moment(date).isBetween(item.tanggalMulai ? item.tanggalMulai.slice(0, 10) : MaxMinDate.MIN_DATE,
                                                                    item.tanggalSelesai ? item.tanggalSelesai.slice(0, 10) : MaxMinDate.MAX_DATE,
                                                                    null,
                                                                    []
                                                ))
                                        )
                                        const mappingJadwal = this.mappingListDokterByJadwal(jadwalInThisTime, item.holiday)
                                        List.push(...mappingJadwal)
                                    }
                                }
                            })
                        }
                    })
                }
                resolve(List)
            } catch {
                console.log('getDokterByJadwal')
                reject(new Error('mapping dokter not Support'))
            }
            
        })
    }

    mappingListDokterByJadwal (list, holiday) {
        const data =  list.map(item => {
            return {
                id: item.id,
                nama: item.dokter ? item.dokter.nama : '',
                idDokter: item.dokter ? item.dokter.id: '',
                durationInMinute: item.durationInMinute,
                jamMulai: item.jamMulai,
                jamSelesai: item.jamSelesai,
                data: item,
            }
        })
        return data.reduce((prevItem, nextItem) => {
            const jadwal = {
                id: nextItem.id,
                durationInMinute: nextItem.durationInMinute,
                jamMulai: nextItem.jamMulai,
                jamSelesai: nextItem.jamSelesai,
                data: nextItem.data
            }
            const index = prevItem.findIndex(item => item.id === nextItem.idDokter)
            if(index >= 0) {
                prevItem[index].jadwal.push(jadwal)
            } else {
                const obj = {
                    holiday,
                    id: nextItem.idDokter,
                    name: nextItem.nama,
                    jadwal: [jadwal]
                }
                prevItem.push(obj)
            }
            return prevItem
        }, [])
    }

    pickDokterValue (data, type) {
        return {
            ...data,
            type
        }
    }

    sendParamsDokter({type, id, date}) {
        let params = {
            "tanggalMulai.equals": moment(date).format("YYYY-MM-DD"),
            "tanggalSelesai.equals": moment(date).format("YYYY-MM-DD")
        }
        if( type === SlotJadwalType.POLI ) {
            params.tujuanRujukanId = id;
        } else if (type === SlotJadwalType.DOKTER) {
            params["id.in"] = id;
        }
        return params
    }

    sendParamsQueue({date, list}) {
        let param = {
            tanggalKunjunganMulai: moment(date).format("YYYY-MM-DD"),
            tanggalKunjunganSelesai: moment(date).format("YYYY-MM-DD")
        }
        let params = ''
        list.forEach(item => {
            params += `ids=J${item.id}&`
        })
        params += `tanggalKunjunganMulai=${param.tanggalKunjunganMulai}&tanggalKunjunganSelesai=${param.tanggalKunjunganSelesai}`
        return params
    }

    sendParamsQueue2({date, list}) {
        let param = {
            tanggal: date
        }
        let params = ''
        Object.keys(param).forEach(key => {
              params += `${key}=${param[key]}&`
        });
        list.forEach((item, index)=> {
            if (index == list.length - 1) {
                params += `dokterId=${item.id}`
            } else {
                params += `dokterId=${item.id}&`
            }
        })
        return params
    }

    setMapObjectEventSlot ({id,start,end, data, hexColor, type, widthMerge, reservasi, index, personInSlot}) {
        return {
            hexColor,
            data,
            start,
            end,
            id,
            type,
            widthMerge,
            reservasi,
            index,
            personInSlot
        }
    }

    // timeSet(index, jam, duration, date){
    //     const splitJam = jam.split(":")
    //     const timeNow = (parseInt(splitJam[0])*60 + parseInt(splitJam[1])) + (index * duration)
    //     let timeAfter = `${(Math.floor(timeNow/60)).toString().padStart(2, '0')}:${(timeNow%60).toString().padStart(2, '0')}`
    //     return `${date}T${timeAfter}:00`
    // }

    getSlotEmptyJadwalWithDuration (dokter, date) {
        return new Promise ((resolve, reject) => {
            try {
                let getSlotData = []
                let jadwal = dokter.jadwal
                const holiday = dokter.holiday
                jadwal.forEach(async (item, index) => {
                    const checkKetidakHadiran = await this.checkCancelStatusJadwalDokters(item.data.statusJadwalDokters, date)
                    let countSlot = this.getCountSLot(item.jamMulai, item.jamSelesai, item.durationInMinute)
                    let i = 0
                    const slotIndexBlocked = item.data.slotIndexBlocked ? item.data.slotIndexBlocked : []
                    const timeNow = moment(`${date} ${item.jamMulai}`, 'YYYY-MM-DD HH:mm')
                    while (i < countSlot) {
                        const objData = this.setMapObjectEventSlot({
                            index,
                            hexColor: `#${this.changeColorBorderSlot2(item.id)}`,
                            data: item.data,
                            id: dokter.id,
                            personInSlot: item.data.personInSlot,
                            start: timeNow.add( 0 , 'minutes').format("YYYY-MM-DDTHH:mm:ss"),
                            end: timeNow.add((item.durationInMinute), 'minutes').format("YYYY-MM-DDTHH:mm:ss"),
                            type: (slotIndexBlocked.find(item => item == i) >= 0 ) || checkKetidakHadiran ? SlotJadwalType.BLOCKED : SlotJadwalType.EMPTY
                        })
                        if(!holiday || objData.type !== SlotJadwalType.EMPTY) {
                            getSlotData.push(objData)
                        }
                        i++
                    }
                })
                resolve(getSlotData)
            }catch {
            // console.log('error getSlotEmptyJadwalWithDuration')
                reject(new Error('slot Dokter not support'))
            }
        })
    }

    getSlotReservedJadwal (jadwal, date, jadwalList) {
        return new Promise ((resolve, reject) => {
            const dataArr = jadwal.data ? jadwal.data : jadwal
            try {
                let slotJadwal = []
                dataArr.forEach((item) => {
                    const index = jadwalList.findIndex(itemData =>itemData && itemData.id === item.jadwal.id)
                    const walkIn = this.getSlotWalkIn(item, date)
                    slotJadwal = [...slotJadwal, 
                        ...this.getSlotJadwal(item, date, index),
                        ...(walkIn.id ? [walkIn]: [])
                        ]
                })
                resolve(slotJadwal)
            }
            catch {
                reject(new Error('Get Reserved Jadwal gagal'))
            }
        })
    }

    checkCancelStatusJadwalDokters (list, date) {
        return new Promise((resolve) => {
            let check = false
            try {
                list.forEach(item => {
                    if(item && item.status === 'CANCELLED' && item.tanggal === date) {
                        check = true
                    }
                })
                resolve(check)
            }catch {
                console.log('error checkCancelStatusJadwalDokters')
                resolve(check)
            }
        })
       

    }

    getSlotWalkIn (list, date) {
        let objData = {}
        if (list.queueWalkIns && list.queueWalkIns.length > 0) {
                const waitingList = list.queueWalkIns.filter(item => item.reservasi)
                if(waitingList.length == 0) return objData

                objData = {
                    id: list.jadwal.dokter.id,
                    start: date,
                    hexColor: `#${this.changeColorBorderSlot2(list.jadwal.id)}`,
                    type: SlotJadwalType.WAITING,   
                    dokter: list.jadwal.dokter,
                    waitingCount: waitingList.length,
                    data: waitingList.map(item => {
                        return {
                            ...item,
                            jadwal: list.jadwal
                        }
                    })
                }
        }
        return objData
    }

    getSlotJadwal (items, date, index) {
        let internalEvent = []
        if(items.slotJadwals && items.slotJadwals.length > 0) {
            items.slotJadwals.forEach(item => {
                const objData = this.setMapObjectEventSlot({
                    index,
                    hexColor: `#${this.changeColorBorderSlot2(items.jadwal.id)}`,
                    data: items.jadwal,
                    reservasi: item,
                    id: item.dokterId,
                    start: `${date}T${item.jamMulai}:00`,
                    end: `${date}T${item.jamSelesai}:00`,
                    type:  SlotJadwalType[item.slotType] === SlotJadwalType.APPOINTMENT ? 
                        item.slotDefect ? SlotJadwalType.DEFECT :
                        SlotJadwalType.RESERVED : SlotJadwalType[item.slotType] 
                })
                internalEvent.push(objData)
            })
        }
        return internalEvent
    }


    getCountSLot (mulai, selesai, durationSlot) {
        const jamMulai = mulai.split(":");
        const jamSelesai = selesai.split(":");
        const minuteJamMulai =
            parseFloat(jamMulai[0] * 60) + parseFloat(jamMulai[1]);
        const minuteJamSelesai =
            parseFloat(jamSelesai[0] * 60) + parseFloat(jamSelesai[1]);
        const durationMinute = minuteJamSelesai - minuteJamMulai;
        return Math.floor(durationMinute / durationSlot);
    }

    getTimeSlot(time) {
        const xTime = time.split(':')
        const minuteTime = parseFloat(xTime[0] * 60) + parseFloat(xTime[1]);
        return minuteTime
    }

    checkTimeInSlot(time, diffStart, diffEnd, isSame) {
        if(isSame) {
            if (time >= diffStart && time < diffEnd) return true
            return false
        } else {
            if (time > diffStart && time <= diffEnd) return true
            return false
        }
    } 

    setOrderDisplay(type) {
        if (type === SlotJadwalType.INTERNAL_EVENT) return 0
        else if (type === SlotJadwalType.TELAT) return 1
        else if (type === SlotJadwalType.RESERVED || type == SlotJadwalType.ANTRIAN)return 2
        else if (type === SlotJadwalType.DEFECT) return 3
        else if (type === SlotJadwalType.EMPTY || type === SlotJadwalType.BLOCKED) return 4
    }

    haveClaimSlotEmpty(timeStart1, timeEnd1, timeStart2, timeEnd2) {
        if(timeStart1 == timeStart2 && timeEnd1 == timeEnd2)  return true
        else {
            const xTimeBeetween1 = moment(timeStart1).isBetween(timeStart2, timeEnd2, null, '[)')
            const xTimeBeetween2 = moment(timeEnd1).isBetween(timeStart2, timeEnd2, null, '(]')
            const xTimeBeetween3 = moment(timeStart2).isBetween(timeStart1, timeEnd1, null, '[)')
            const xTimeBeetween4 = moment(timeEnd2).isBetween(timeStart1, timeEnd1, null, '(]')
            if(xTimeBeetween1 || xTimeBeetween2 || xTimeBeetween3 || xTimeBeetween4) return true
            else return false
        }
    }

    haveClaimSlotEmptyTime(timeStart1, timeEnd1, timeStart2, timeEnd2) {
        const xTimeStart1 = this.getTimeSlot(timeStart1)
        const xTimeEnd1 = this.getTimeSlot(timeEnd1)
        const xTimeStart2 = this.getTimeSlot(timeStart2)
        const xTimeEnd2 = this.getTimeSlot(timeEnd2)
        const diffTime1 = this.checkTimeInSlot(xTimeStart1, xTimeStart2, xTimeEnd2, true)
        const diffTime2 = this.checkTimeInSlot(xTimeEnd1, xTimeStart2, xTimeEnd2, false)
        const diffTime3 = this.checkTimeInSlot(xTimeStart2, xTimeStart1, xTimeEnd1, true)
        const diffTime4 = this.checkTimeInSlot(xTimeEnd2, xTimeStart1, xTimeEnd1, false)
        if(diffTime1 || diffTime2 || diffTime3 || diffTime4) return true
        else return false
    }

    haveClaimInSlotJadwal (timeStart1, timeEnd1, timeStart2, timeEnd2) {
        const xTimeStart1 = this.getTimeSlot(timeStart1)
        const xTimeEnd1 = this.getTimeSlot(timeEnd1)
        const xTimeStart2 = this.getTimeSlot(timeStart2)
        const xTimeEnd2 = this.getTimeSlot(timeEnd2)
        if (xTimeStart2 <= xTimeStart1 && xTimeEnd2 >= xTimeEnd1) return true
        return false
    }

    mergeAllSlot(data1, data2, totalJadwal) {
        return new Promise((resolve) => {
            for(let i = 0; i < data1.length; i++) {
                data1[i].index = data1[i].index * totalJadwal + this.setOrderDisplay(data1[i].type)
                if (data1[i].type === SlotJadwalType.RESERVED ||
                    data1[i].type === SlotJadwalType.ANTRIAN ||
                    data1[i].type === SlotJadwalType.DEFECT) {
                    
                    const sliceEmptySlot = data2.filter(item =>
                            item.type == SlotJadwalType.EMPTY
                            && this.haveClaimSlotEmpty(item.start, item.end, data1[i].start, data1[i].end)
                            && item.hexColor == data1[i].hexColor)
                      
                    if(sliceEmptySlot && sliceEmptySlot.length > 0){
                        sliceEmptySlot.forEach(_item => {
                            const index = data2.findIndex(_item2 => _item2.id == _item.id  && _item2.hexColor == _item.hexColor && _item2.start == _item.start)
                            if (data2[index].personInSlot > 1) {
                                data2[index].personInSlot -= 1
                            } else {
                                data2.splice(index, 1)
                            }
                        })
                      }
                }
            }
            data2 = data2.map(item => {
                return {
                    ...item,
                    index: item.index * totalJadwal + this.setOrderDisplay(item.type)
                }
            })
            resolve([...data2, ...data1])
        })  
    }

    setHoliday({date, id, data}) {
        return {
            title: JSON.stringify(data),
            resourceId: id,
            start: `${date}T07:00:00`,
            end: `${date}T24:00:00`,
            displayOrder: -1,
            classNames: ['bg-transparent', 'none-border']
        }
    }

    bisnisSlot({data, before, max}) {
        return new Promise(resolve => {
            const rest = max - before
            const dataLast = this.setDataSlot(data, '', rest)
            resolve(dataLast)
        })
    }

    setDataSlot(data, lastId, rest, dataCallBack = []) {
        data = data.filter(item => item.resourceId)
        if(data[0] && data[0].resourceId){
            if(lastId == data[0].resourceId) {
                return dataCallBack
            }
            lastId = data[0].resourceId
            const withResource = data.filter(item => item.resourceId == lastId)
            if(rest >= withResource.length) {
                dataCallBack.push(...withResource)
                rest -= withResource.length
            }
            let afterData = data.filter(item => lastId && item.resourceId != lastId)
            return this.setDataSlot(afterData,lastId, rest, dataCallBack)
        } else {
            return dataCallBack
        }
    }
    
}