import { RepositoryFactory } from "../repositories/BaseRepositoryFactory"
import TempateNotification from '../model/template-notification'
const NotifikasiChannel = RepositoryFactory.get('NotifikasiChannel')
const HistoryNotification = RepositoryFactory.get('HistoryNotification')

import router from '../../router/router'

export default {
    namespaced: true,
    state: {
        listAllTemplate: [],
        listDetailTemplate: new TempateNotification(),
        smsTemplate: {},
        emailTemplate: {},
        fcmTemplate: {},
        whatsappTemplate: {},
        isLoadingNotifikasi: false,
        isBtnCanEdit: true,
        isModalWhatsapp: false,
        isModalFCM: false,
        isModalEmail: false,
        isModalSMS: false,
        detailWhatsapp: {},
        detailFCM: {},
        detailEmail: {},
        detailSMS: {},
        isLoadSpinner: false,
        isHaveTemplate: false,
        hierarkiParent: [],
        listChannelUsed: [],
        isLoadingHierarki: true,
        isLoadingSendNotif: false,
        isLoadingSearchTemplate: false,
        listSearchTemplate: []
    },
    mutations: {
        SET_LOADING_SEND_NOTIF(state, res){
            state.isLoadingSendNotif = res
        },
        SET_LIST_SEARCH_TEMPLATE(state, res){
            state.listSearchTemplate = res
        },
        SET_LOADING_SEARCH_TEMPLATE(state, res){
            state.isLoadingSearchTemplate = res
        },
        SET_LIST_CHANNEL_USED(state, res) {
            state.listChannelUsed = res
        },
        SET_LOADING_HIERARKI(state, res) {
            state.isLoadingHierarki = res
        },
        SET_HIERARKI_PARENT(state, {active, name}) {
            if(active){
                const template = {
                    id: name,
                    name,
                    backupChannel: []
                }
                state.hierarkiParent.push(template)
            } else {
                state.hierarkiParent = state.hierarkiParent.filter(item => item.name != name)
            }
        },
        SET_HIERARKI_NULL(state) {
            state.hierarkiParent = []
        },
        SET_TEMPLATE_HAVE_ID(state, res) {
            state.isHaveTemplate = res
        },
        SET_LOAD_SPINNER(state, res) {
            state.isLoadSpinner = res
        },
        SET_DETAIL_WHATSAPP(state, res) {
            state.detailWhatsapp = res
        },
        SET_DETAIL_FCM(state, res) {
            state.detailFCM = res
        },
        SET_MODAL_FCM(state, res) {
            state.isModalFCM = res
        },
        SET_DETAIL_EMAIL(state, res) {
            state.detailEmail = res
        },
        SET_DETAIL_SMS(state, res) {
            state.detailSMS = res
        },
        SET_MODAL_EMAIL(state, res) {
            state.isModalEmail = res
        },
        SET_MODAL_WHATSAPP(state, res) {
            state.isModalWhatsapp = res
        },
        SET_MODAL_SMS(state, res) {
            state.isModalSMS = res
        },
        SET_IS_BUTTON_CAN_EDIT(state, res) {
            state.isBtnCanEdit = res
        },
        SET_TO_DEFAULT_NOTIF_TEMPLATE(state) {
            state.listDetailTemplate = new TempateNotification()
        },
        SET_LOADING_NOTIFIKASI(state, res) {
            state.isLoadingNotifikasi = res
        },
        SET_ALL_TEMPLATE(state, res) {
            state.listAllTemplate = res
        },
        SET_DETAIL_TEMPLATE(state, res) {
            state.listDetailTemplate = res
        },
        SET_SMS_TEMPLATE(state, res) {
            state.smsTemplate = res
        },
        SET_FCM_TEMPLATE(state, res) {
            state.fcmTemplate = res
        },
        SET_WHATSAPP_TEMPLATE(state, res) {
            state.whatsappTemplate = res
        },
        SET_EMAIL_TEMPLATE(state, res) {
            state.emailTemplate = res
        },
    },
    actions: {
        async DOWNLOAD_TEMPLATE_EMAIL({ commit }, id) {
            commit('SET_LOADING_NOTIFIKASI', true)
            const response = await NotifikasiChannel.downloadTmplate(id).catch(err => {
                // console.log('errorr')
                commit('SET_LOADING_NOTIFIKASI', false)
                toastr.error('Error Download File')
            })
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id}.html`); //or any other extension
            document.body.appendChild(link);
            link.click();
            commit('SET_LOADING_NOTIFIKASI', false)
        },
        // async GET_TEMPLATE_CHANNEL({commit}) {
        //   commit('SET_LOADING_NOTIFIKASI', true)
        //   try{
        //     const {data} = await NotifikasiChannel.getDataNotifTemplate()
        //     commit('SET_ALL_TEMPLATE', data)
        //   } catch {
        //     toastr.error('Data Error');
        //   }
        //   commit('SET_LOADING_NOTIFIKASI', false)
        // },
        async GET_TEMPLATE_CHANNEL_BY_ID({ commit }, id) {
            
            commit('SET_DETAIL_TEMPLATE', new TempateNotification())
            try {
                const { data } = await NotifikasiChannel.getDataNotifTemplateById(id)
                commit('SET_DETAIL_TEMPLATE', data)
                commit('SET_IS_BUTTON_CAN_EDIT', false)
                commit('SET_LIST_CHANNEL_USED', data.channelUsed ? data.channelUsed : [])
            } catch {
                commit('SET_IS_BUTTON_CAN_EDIT', true)
                toastr.error('Data Error');
            }
            commit('SET_LOADING_NOTIFIKASI', false)
        },
        async CHECK_TEMPLATE_CHANNEL_BY_ID({ commit }, id) {
            commit('SET_LOADING_NOTIFIKASI', true)
            commit('SET_TEMPLATE_HAVE_ID', true)
            try{
                const params = {'templateId.in': id}
                const { data } = await NotifikasiChannel.fetch(params)
                if(data.length > 0) {
                    commit('SET_TEMPLATE_HAVE_ID', true)
                } else {
                    commit('SET_TEMPLATE_HAVE_ID', false)
                }
            } catch {
                commit('SET_TEMPLATE_HAVE_ID', true)
            }
            commit('SET_LOADING_NOTIFIKASI', false)
        },
        async POST_TEMPLATE_CHANNEL({ commit }, { data, params }) {
            commit('SET_LOADING_NOTIFIKASI', true)

            try {
                const { data1 } = await NotifikasiChannel.postNotifTemplate(data)
                if (params == 'add') {
                    toastr.success('Berhasil Tambah Data')
                } else if (params == 'delete') {
                    toastr.success('Berhasil Menghapus Data')
                } else {
                    toastr.success('Berhasil Edit Data')
                }

            } catch {
                toastr.error('Data Error');
            }

            commit('SET_LOADING_NOTIFIKASI', false)
            router.push({ name: 'ManajementNotifikasi' })
        },
        async GET_HISTORY_DETAIL_WHATSAPP({ commit }, id) {
            commit('SET_LOAD_SPINNER', true)
            commit('SET_DETAIL_WHATSAPP', {})
            try {
                const { data } = await HistoryNotification.searchWhatsappbyId(id)
                commit('SET_DETAIL_WHATSAPP', data)
            } catch {
                toastr.error('Data Error');
            }
            commit('SET_LOAD_SPINNER', false)
        },
        async GET_HISTORY_DETAIL_SMS({ commit }, id) {
            commit('SET_LOAD_SPINNER', true)
            commit('SET_DETAIL_SMS', {})
            try {
                const { data } = await HistoryNotification.searchSMSbyId(id)
                commit('SET_DETAIL_SMS', data)
            } catch {
                toastr.error('Data Error');
            }
            commit('SET_LOAD_SPINNER', false)
        },
        async GET_HISTORY_DETAIL_FCM({ commit }, id) {
            commit('SET_LOAD_SPINNER', true)
            commit('SET_DETAIL_FCM', {})
            try {
                const { data } = await HistoryNotification.searchFCMbyId(id)
                commit('SET_DETAIL_FCM', data)
            } catch {
                toastr.error('Data Error');
            }
            commit('SET_LOAD_SPINNER', false)
        },
        async GET_HISTORY_DETAIL_EMAIL({ commit }, id) {
            commit('SET_LOAD_SPINNER', true)
            commit('SET_DETAIL_EMAIL', {})
            try {
                const { data } = await HistoryNotification.searchEmailbyId(id)
                commit('SET_DETAIL_EMAIL', data)
            } catch {
                toastr.error('Data Error gaess');
            }
            commit('SET_LOAD_SPINNER', false)
        },
        async SEARCH_TEMPLATE_WITH_QUERY({commit}, query){
            try {
                const containsTemplate = {'templateId.contains': query, size: 30}
                const { data } = await NotifikasiChannel.fetch(containsTemplate)
                commit('SET_LIST_SEARCH_TEMPLATE', data)
                commit('SET_LOADING_SEARCH_TEMPLATE', false)
            } catch {
                commit('SET_LOADING_SEARCH_TEMPLATE', false)
                toastr.error('Data Error');
            }
        },
        async SEND_NOTIF({commit}, body) {
            commit('SET_LOADING_SEND_NOTIF', true)
            try {
                await NotifikasiChannel.postSendNotification(body)
                commit('SET_LOADING_SEND_NOTIF', false)
                toastr.success('Berhasil Mengirim Pesan')

            } catch {
                commit('SET_LOADING_SEND_NOTIF', false)
                toastr.error('Gagal Mengirim Pesan');
            }
            
        }
    }
}

