export default function Payment() {
    return {
        id: null,
        name: null,
        icon: '',
        imageContentType: '',
        description: null,
        enabled: true,
        clientId: '',
        expiry: '0',
        expiryUnit: 'DAY',
        parentId: '',
        hasChild: false,
        afterInstruction:'',
        payload: '',
        instruction: {
            overview: [''],
            detail: [],
            mobile: false,
            payload:[]
        }
    };
}
