import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
const defaultConfigs = { params: { "size": 1000 } };
import createRequestOption from './RequestParamUtil';

export default {
    reportPerujuk(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('/report/perujuks', req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    reportReferral(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('/report/transaksi-referrals', req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    reportQueueCount(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('/report/queue-episode-count', req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    reportReferralRevenue(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('/report/referral-revenue', req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    referralStageCount(req) {
        return Repository.get('/report/transaksi-referral-stage-count', createRequestOption(req));
    },
    reportTopTotalRujukan(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('/report/transaksi-referral-top-total-rujukan', req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    reportTopTotalBiaya(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate('/report/transaksi-referral-top-total-biaya', req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    reportReservasiHistroy(req) {
        return Repository.get('/report/reservasi-pasien', createRequestOption(req));
    },
    reportReservasiAudit(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginateAppointmentWithCount('/v3/reservasi-audit', req, [], 0, resolve, reject);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    }

}