import { RepositoryFactory } from "../repositories/BaseRepositoryFactory"
const PaymentMethod = RepositoryFactory.get('PaymentMethod')
import Payment from "./../model/payment-model";
import router from '../../router/router'
import ConfigGlobalPayment from "../model/config-payment-global";

export default {
    namespaced: true,
    state: {
        paymentDetail: new Payment(),
        paymentMethod: [],
        listPaymentGlobal: [],
        loadingAPI: false,
        isLoadingConfig: false,
        listChannels: [],
        litTransactionChannels: [],
        listPaymentMethodChannel: [],
        listPaymentConfig: [],
        detailTransaksiClient: {},
        detailPayment:{},
        isLoadingPayment: false,
        defaultConfigGlobal: new ConfigGlobalPayment().config,
        defaultConfigGlobal1: new ConfigGlobalPayment().configGlobal,
        isLoadingConfigGlobal: false,
        configPaymentClient: [],
        isLoadingWebhook: false,
        isLodingCreatePayment: false,
        // isLoginPayment: false

    },
    mutations: {
      SET_LOADING_CREATE_PAYMENT(state, res) {
        state.isLodingCreatePayment = res
      },
      // SET_LOGIN_PAYMENT(state, res){
      //   state.isLoginPayment = res
      // },
      SET_LOADING_WEBHOOK(state, res){
        state.isLoadingWebhook = res
      },
      SET_PAYMENT_CONFIG_CLIENT(state, res){
        state.configPaymentClient = res
      },
      SET_LOADING_CONFIG_GLOBAL(state, res) {
        state.isLoadingConfigGlobal = res
      }, 
      SET_DEFAULT_CONFIG_GLOBAL(state, res){
        state.defaultConfigGlobal = res
      },
      SET_DEFAULT_CONFIG_GLOBAL1(state, res){
        state.defaultConfigGlobal1 = res
      },
      SET_LOADING_PAYMENT(state, res) {
        state.isLoadingPayment = res
      },
      SET_DETAIL_PAYMENT(state, res) {
        state.detailPayment = res
      },
      SET_DETAIL_TRANSAKSI_CLIENT(state, res) {
        state.detailTransaksiClient = res
      },
      SET_PAYMENT_CONFIG(state, res) {
        state.listPaymentConfig = res
      },
      SET_LOADING_CONFIG(state, res) {
        state.isLoadingConfig = res
      },
      SET_ALL_DATA(state, res){ 
        state.listPaymentGlobal = JSON.parse(JSON.stringify(res))
      },
      SET_PAYMENT_CHANNELS(state, res){
        state.listPaymentMethodChannel = res
      },
      SET_TRANSACTION_CHANNELS(state, res) {
        state.litTransactionChannels = res
      },
      SET_LIST_CHANNELS(state, res) {
        state.listChannels = res
      },
        SET_PAYMENT_DETAIL(state, res) {
            state.paymentDetail = res
        },
        SET_LOADING_API(state, res) {
          state.loadingAPI = res
        },
        SET_PAYMENT_METHOD(state, res) {
          state.paymentMethod = res
        }
    },
    actions: {
      async GET_ALL_PAYMENT_METHODS({commit, dispatch}) {
        commit('SET_LOADING_API', true)
        try{
          const {data} = await PaymentMethod.getAllData()
          commit('SET_ALL_DATA', data)
          dispatch('GROUPING_DATA', data)
        } catch {
          toastr.error('Data Error');
        }
        commit('SET_LOADING_API', false)
      },
      GROUPING_DATA({commit}, data) {
        // console.log(data)
        const allData = []
        for(let i = 0; i < data.length; i++) {
          if(!data[i].parentId) {
            allData.push(data[i])
          } 
          else {
             const idAllData = allData.findIndex(item => item.id ==  data[i].parentId)
             const idData = data.findIndex(item => item.id ==  data[i].parentId)
             if(idAllData >= 0) {
               if(!allData[idAllData].children) {
                allData[idAllData].children = []
               }
               allData[idAllData].collapse = 1
               allData[idAllData].children.push(data[i])
             }else if(idData >= 0) {
              if(!data[idData].children) {
                data[idData].children = []
               }
               data[idData].collapse = 1
               data[idData].children.push(data[i])
             }
          }
        }
        commit('SET_PAYMENT_METHOD', allData)
      },
      async GET_PAYMENT_METHODS_BY_ID({commit}, id) {
        commit('SET_LOADING_API', true)
        try{
          let payment = new Payment()
          let payment2 = new Payment()
          // console.log(payment)
          const {data} = await PaymentMethod.getIDPaymentMethod(id)
          // console.log('data', data)
          payment = {...payment, ...data}
          try {
            payment.instruction = JSON.parse(payment.instruction)
          } catch {
            payment.instruction = payment.instruction
          }
          if(!payment.instruction || payment.instruction == 'string') {
            payment.instruction = payment2.instruction
          }
          commit('SET_PAYMENT_DETAIL', payment)
        }catch {
          toastr.error('Error Data')
        }
        commit('SET_LOADING_API', false)
      },
      async POST_PAYMENT_METHODS({}, payload) {
        const helpPayload = JSON.parse(JSON.stringify(payload))
        if(helpPayload.instruction && typeof helpPayload.instruction == 'object') {
            helpPayload.instruction = JSON.stringify(helpPayload.instruction)
        }
        const paymentMethodRepo = payload.id ?  PaymentMethod.putPaymentMethod(helpPayload): PaymentMethod.postPaymentMethod(helpPayload)
        await paymentMethodRepo
        .then(() => {
            toastr.success(payload.id ? 'Sukses merubah data': 'Sukses menambah data')
            router.push({name: 'payment-global'})
        })
        .catch(()=> {
            toastr.error('Failed Send Payment')
        })
      },
      async GET_ALL_TRANSACTION_USER({commit}) {
        try{
          commit('SET_LOADING_API', true)
          const {data} = await PaymentMethod.getAllUserTransactionUser()
          commit('SET_LIST_CHANNELS', data)
          
        } catch {
          toastr.error('Data Error');
        }
        commit('SET_LOADING_API', false)
      },
      async GET_ALL_TRANSACTION_USER_BY_ID({commit}, id) {
        try{
          commit('SET_LOADING_API', true)
          const {data} = await PaymentMethod.getAllUserTransactionUser()
          const item = data.filter(itemD => itemD.userId == id)
          commit('SET_DETAIL_TRANSAKSI_CLIENT', item[0])
          
        } catch {
          toastr.error('Data Error');
        }
        commit('SET_LOADING_API', false)
      },
      async GET_TRANSACTION_CHANNELS({commit}, userId) {
        commit('SET_LOADING_API', true)
        try{
          const {data} = await PaymentMethod.getTransactionChannels(userId)
          commit('SET_TRANSACTION_CHANNELS', data)
          
        } catch {
          toastr.error('Data Error');
        }
        commit('SET_LOADING_API', false)
      },
      async GET_PAYMENT_METHOD_BY_CHANNEL({commit}, id) {
        commit('SET_LOADING_API', true)
        try{
          const {data} = await PaymentMethod.getPaymentMethodbyChannel(id)
          commit('SET_PAYMENT_CHANNELS', data)
        }catch {
          toastr.error('Error Data')
        }
        commit('SET_LOADING_API', false)
      },
      async DELETE_PAYMENT_METHOD({dispatch}, id) {
        try {
          await PaymentMethod.deletePaymentMethod(id)
          toastr.success('Payment Berhasil Didelete')
          dispatch('GET_ALL_PAYMENT_METHODS')
        } catch {
          toastr.error('Payment Gagal Dihapus')
        }
      },
      async GET_PAYMENT_CONFIG_CLIENT({dispatch, commit}, id) {
        commit('SET_LOADING_CONFIG', true)
        commit('SET_PAYMENT_CONFIG', [])
          try{
            const {data} = await PaymentMethod.getPaymentConfigsClient(id)
            // console.log(data)
            dispatch('SET_DEFAULT_PAYMENT_CONFIG',data)
            // commit('SET_PAYMENT_CONFIG', data)
          }catch {
            toastr.error('Error Data')
          }
          commit('SET_LOADING_CONFIG', false)
      },
      async POST_PAYMENT_CONFIG({commit, dispatch}, {payload, id}) {
          commit('SET_LOADING_CONFIG', true)
          try{
            const paymentConfig = payload.id ? PaymentMethod.putPaymentConfigClient(payload) : PaymentMethod.postPaymentConfigClient(payload)
            await paymentConfig
            
            toastr.success('Berhasil Menyimpan Data')
            setTimeout(()=> {
              dispatch('GET_PAYMENT_CONFIG_CLIENT', id)
              
            }, 500)
            setTimeout(()=> {
              dispatch('SET_PAYMENT_CLIENT_MERGED_ARRAY')
            }, 1000)
          }catch {
            toastr.error('Error Data')
          }
          commit('SET_LOADING_CONFIG', false)
      },
      async DELETE_PAYMENT_CONFIG({dispatch}, {idConfig, id}) {
        try{
          await PaymentMethod.deletePaymentConfig(idConfig)
          setTimeout(()=> {
            dispatch('GET_PAYMENT_CONFIG_CLIENT', id)
            
          }, 500)
          setTimeout(()=> {
            dispatch('SET_PAYMENT_CLIENT_MERGED_ARRAY')
          }, 1000)
        }catch {
          toastr.error('Error Data')
        }
      },
      async POST_POSITION_PAYMENT({commit}, {payload, isLoading}) {
        if(isLoading) {
          commit('SET_LOADING_API', true)
        } else {
          commit('SET_LOADING_CONFIG', true)
        }
          try{
            await PaymentMethod.postPaymentSettingsClient(payload)
            toastr.success('Berhasil Update Data')
          }catch {
            toastr.error('Error Data')
          }
          if(isLoading) {
            commit('SET_LOADING_API', false)
          } else {
            commit('SET_LOADING_CONFIG', false)
          }
      },
      SET_DEFAULT_PAYMENT_CONFIG({commit}, data) {
        const defaultPayment = new ConfigGlobalPayment().config
        const result =  [...data, ...defaultPayment].reduce((a, b) =>{
           if(a.findIndex(item => item.key == b.key) < 0){
               a.push(b)
           }
           return a
        }, []); 
        commit('SET_PAYMENT_CONFIG', result)
      },
      async GET_CONFIG_PAYMENT_GLOBAL({state, commit}){
        commit('SET_LOADING_CONFIG_GLOBAL', false)
        const listConfigDefault = state.defaultConfigGlobal1
        try{
          const {data} = await PaymentMethod.getPaymentConfigsClient('', true)
          const mergeId = (a1, a2) =>
             (a1).map(key => ({
               ...key,
               ...a2.find((item)=> item &&(item.key === key.key))
             }))
             const mapData = mergeId(listConfigDefault, data)
             commit('SET_DEFAULT_CONFIG_GLOBAL1', mapData)
        } catch {
          toastr.error('Error Data Config Data')
        }
        commit('SET_LOADING_CONFIG_GLOBAL', true)
      },
      async SET_CONFIG_PAYMENT_GLOBAL({dispatch}, payload) {
        try {
          const {data} = await PaymentMethod.postPaymentConfigClient(payload)
          toastr.success('Sukses Menyimpan Config Pembayaran')
          dispatch('GET_CONFIG_PAYMENT_GLOBAL')
        } catch {
          toastr.error('Gagal Menyimpan Config Pembayaran')
        }
      },
      SET_PAYMENT_CLIENT_MERGED_ARRAY({commit, state}){
        const config = JSON.parse(JSON.stringify(state.listPaymentConfig));
        const defaultConfig = JSON.parse(JSON.stringify(state.defaultConfigGlobal))
        const defaultItemName = defaultConfig.map(item => {
          return {
            key: item.key,
            valueDefault: item.value
          }
        })
        const mergeItem = (a1, a2) => 
        (a1).map(key =>({
          ...key,
          ...a2.find((item) => item && (item.key == key.key)),
        }))
       const data = mergeItem(defaultItemName, config)
       const dataAfterMap = data.map(item => {
          return {
            ...item,
            selectRadio: item.id ? '2' : '1'
          }
        })
        commit('SET_PAYMENT_CONFIG_CLIENT', dataAfterMap)
      },
      async GET_PAYMENT_DETAIL({commit}, id){
        commit('SET_LOADING_PAYMENT', true)
        commit('SET_PAYMENT_CONFIG', {})
          try{
            const {data} = await PaymentMethod.getTransactionChannelsById(id)
            data.customerDetail = JSON.parse(data.customerDetail)
            data.itemDetail = JSON.parse(data.itemDetail)
            const createdPayment = {
              transactionStatus: 'created',
              paymentType: ' - ',
              timeExpired: data.expiredAt,
              createdDate: data.createdDate,
              lastModifiedDate: data.createdDate
            }
            // data.payments.unshift(createdPayment)
            data.payments.push(createdPayment)
            commit('SET_DETAIL_PAYMENT', data)
          }catch {
            toastr.error('Error Data')
          }
          commit('SET_LOADING_PAYMENT', false)
      },
      // async LoginWithRealmsCaredokter({commit}, item){
      //   // commit('SET_LOGIN_PAYMENT', false)
      //   commit('SET_LOADING_CREATE_PAYMENT', true)
      //   const options = {
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //     "Access-Control-Allow-Origin": "*",
      //     'Access-Control-Allow-Credentials':true,
      //     'Accept': '*/*'
      //   }
      //   const obj = {
      //     grant_type: 'password',
      //     client_id: 'caredokter-connect',
      //     client_secret: 'M2Wk9D2PYkjfieLZrD88hxrKSXvG4K4G',
      //     username: item.username,
      //     password: item.password,
      //     scope: 'openid'
      //   };
        
      //   const data = Object.keys(obj)
      //     .map((key, index) => `${key}=${encodeURIComponent(obj[key])}`)
      //     .join('&');

      //   axios.post('https://daccount.mandayamedical.group/auth/realms/caredokter/protocol/openid-connect/token',data, {headers: options})
      //     .then(resp => {
      //       commit('SET_LOADING_CREATE_PAYMENT', false)
      //       if(resp.status == 200) {
      //         const Xtime = moment(new Date()).format('x') - 1
      //         const storage= {
      //           exp: (Xtime + resp.data.expires_in),
      //           token: resp.data.access_token
      //         }
      //         localStorage.setItem('payment_client', JSON.stringify(storage))
      //         // commit('SET_LOGIN_PAYMENT', true)
      //       }
      //     })
      //     .catch(err=> {
      //       commit('SET_LOADING_CREATE_PAYMENT', false)
      //       toastr.error(err.response.data.error_description)
      //     })
      // },
      async CREATE_SNAP_PAYMENT({commit}, {data, userId}) {
        commit('SET_LOADING_CREATE_PAYMENT', true)
        // const item = localStorage.payment_client ? JSON.parse(localStorage.payment_client) : {}

        // const options ={
        //   headers: {
        //     'Authorization': `bearer ${item.token}`
        //   }
        // }
        await PaymentMethod.postCreateSnap(data, userId)
        .then(() => {
          toastr.success('Create Snap Payment')
          commit('SET_LOADING_CREATE_PAYMENT', false)
        })
        .catch(()=> {
          toastr.error('Error Data Payment')
          commit('SET_LOADING_CREATE_PAYMENT', false)
        })
        // axios.post(`${process.env.MIX_API_URL}/api/external/transaction/create`, data, options)
        //   .then(resp => {
        //     // if(resp.data.status ) {
        //       toastr.success('Create Snap Payment')
        //       commit('SET_LOADING_CREATE_PAYMENT', false)
        //     // }
        //   })
        //   .catch(err => {
        //     toastr.error('Error Data Payment')
        //     commit('SET_LOADING_CREATE_PAYMENT', false)
        //     if(err.response.status == 401) {
        //       localStorage.removeItem('payment_client')
        //       commit('SET_LOGIN_PAYMENT', false)
        //     }
        //   })
      }
    }
    
}