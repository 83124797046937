// import Repository from './../../loyalty/repositories/BaseRepository';
import Repository from './BaseRepository'
const urlGetTemplateAll = 'msnotification/api/v1/templates'
const urlGetTemplateById = 'msnotification/api/v1/templates-by-id'
const urlGetDownloadEmail = 'msnotification/api/v1/download-email-templates'
const sendNotification = 'msnotification/api/v1/external/send-notification'
export default {
    getDataNotifTemplate() {
        return Repository.get(`${urlGetTemplateAll}`)
    },
    getDataNotifTemplateById(templateId) {
        const params = {
            templateId
        }
        return Repository.get(`${urlGetTemplateById}`, {params})
    },
    
    postNotifTemplate(payload) {
        return Repository.post(`${urlGetTemplateAll}`, payload)
    },
    downloadTmplate(id){
        const options =  { responseType: 'blob' }
        return Repository.get(`${urlGetDownloadEmail}/${id}`, options)
    },
    fetch(req) {
        const defaultConfigs = requestParams(req);
        return Repository.get(`${urlGetTemplateAll}`, defaultConfigs);
    },
    postSendNotification(body){
        return Repository.post(`${sendNotification}`, body)
    }
}

function requestParams(req) {
    var options = new URLSearchParams();
    if( req ) {
        Object.keys(req).forEach(key => {
                options.set(key, req[key]);
        });
    }
    var request = {
        params: options
    }
    return request;
    }