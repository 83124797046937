// import Repository from './../../loyalty/repositories/BaseRepository';
import Repository from './BaseRepository'
const emailGetAll = 'msnotification/api/v1/email-histories'
const fcmGetAll = 'msnotification/api/v1/fcm-histories'
const whatsappGetAll = 'msnotification/api/v1/whatsapp-histories'
const smsGetAll = 'msnotification/api/v1/sms-histories'
const historyNotif = 'msnotification/api/v1/history-notifications'
        
export default {
    fetchSMS(req) {
        const defaultConfigs = requestParams(req);
        return Repository.get(`${smsGetAll}`, defaultConfigs);
    },
    searchSMS(req, param = {}) {
        const data = {...req, ...param}
        const defaultConfigs = requestParams(data);
        return Repository.get(`${smsGetAll}`, defaultConfigs);
    },
    fetchEmail(req) {
        const defaultConfigs = requestParams(req);
        return Repository.get(`${emailGetAll}`, defaultConfigs);
    },
    searchEmail(req, param) {
        const data = {...req, ...param}
        const defaultConfigs = requestParams(data);
        return Repository.get(`${emailGetAll}`, defaultConfigs);
    },
    searchEmailbyId(id) {
        return Repository.get(`${emailGetAll}/${id}`);
    },
    fetchFCM(req) {
        const defaultConfigs = requestParams(req);
        return Repository.get(`${fcmGetAll}`, defaultConfigs);
    },
    searchFCM(req, params) {
        const data = {...req, ...params}
        const defaultConfigs = requestParams(data);
        return Repository.get(`${fcmGetAll}`, defaultConfigs);
    },
    searchFCMbyId(id) {
        return Repository.get(`${fcmGetAll}/${id}`);
    },
    fetchWhatsapp(req) {
        const defaultConfigs = requestParams(req);
        return Repository.get(`${whatsappGetAll}`, defaultConfigs);
    },
    searchWhatsapp(req, params) {
        const data = {...req, ...params}
        const defaultConfigs = requestParams(data);
        return Repository.get(`${whatsappGetAll}`, defaultConfigs);
    },
    searchWhatsappbyId(id) {
        return Repository.get(`${whatsappGetAll}/${id}`);
    },
    searchSMSbyId(id) {
        return Repository.get(`${smsGetAll}/${id}`);
    },
    fetchHistoryNotif(req) {
        const defaultConfigs = requestParams(req);
        return Repository.get(`${historyNotif}`, defaultConfigs);
    }
}

function requestParams(req) {
    var options = new URLSearchParams();
    if( req ) {
        Object.keys(req).forEach(key => {
                options.set(key, req[key]);
        });
    }
    var request = {
        params: options
    }
    return request;
}