export default function ConfigGlobalPayment() {
    return {
       config:  [
        {
          key: "PAYMENT_LINK_EXPIRED",
          value: ''
        },
        {
          key: "CRON_SCHEDULE_REFRESH_STATUS_KEY",
          value: ''
        },
        {
          key: "PAYMENT_WEBHOOK",
          value: ''
        }
      ],
      configGlobal: [
        {
          key: "PAYMENT_LINK_EXPIRED",
          value: ''
        },
        {
          key: "CRON_SCHEDULE_REFRESH_STATUS_KEY",
          value: ''
        }
      ]
    };
}
