export default function TemplateNotification() {
    return {
        emailTemplate: {
            bcc: [],
            cc: [],
            file: '',
            linkFile: '',
            requestMethod: false,
            sender: '',
            subject: ''
        },
        fcmTemplate: {
            action: '',
            content: '',
            requestMethod: false,
            title: ''
        },
        smsTemplate: {
            content: '',
            requestMethod: false,
            status: 'SUBMITTED',
            type: 'PREMIUM'
        },
        whatsappTemplate: {
            paramMappings: [{
                whatsappParam: '',
                templateParam: ''
            }],
            requestMethod: false,
            whatsappTemplateId: ''
        },
        templateId: '',
        templateName: ''
    };
}
