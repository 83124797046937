import Vue from "vue";
import Vuex from "vuex";
import createMultiTabState from "vuex-multi-tab-state";

Vue.use(Vuex);
import MasterStore from "./MasterStore";
import ChatStore from "./ChatStore";
import PharmacyStore from "./PharmacyStore";
import PharmacyPicStore from "./PharmacyPicStore";
import CallerStore from "./CallerStore";
import UnitCallerStore from "./UnitCallerStore";
import DoctorCallerStore from "./DoctorCallerStore";
import DoctorAppointmentStore from "./DoctorAppointmentStore";
import MonthCalendarStore from "./MonthCalendarDoctorAppointmentStore";
import DayCalendarStore from "./DayCalendarDoctorAppointmentStore";
import Crud from "../loyalty/store/crud";
import MemberListDialog from "../loyalty/store/member-list-dialog";
import DriveThruStore from "./DriveThruStore";
import PaymentStore from '../ecommerce/store/PaymentStore'
import NotifikasiStore from '../ecommerce/store/NotifikasiStore';

export default new Vuex.Store({
    modules: {
        MasterStore,
        ChatStore,
        PharmacyStore,
        PharmacyPicStore,
        CallerStore,
        UnitCallerStore,
        DoctorCallerStore,
        DoctorAppointmentStore,
        MonthCalendarStore,
        Crud,
        MemberListDialog,
        DayCalendarStore,
        DriveThruStore,
        PaymentStore,
        NotifikasiStore
    },
    plugins: [
        createMultiTabState({
            statesPaths: [
                "CallerStore.userNotification",
                "CallerStore.historyUnitCaller",
                "CallerStore.historyDokterCaller"
            ]
        })
    ]
});
