import PaymentMethodRepository from "./PaymentMethodRepository";
import NotifikasiTemplateRepository from "./NotifikasiChannel"
import HistoryNotificationRepository from './HistoryNotifRepository'
const repositories = {
    PaymentMethod: PaymentMethodRepository,
    NotifikasiChannel: NotifikasiTemplateRepository,
    HistoryNotification: HistoryNotificationRepository
};

export const RepositoryFactory = {
    get: name => repositories[name]
};