export default {
    DEFECT: 'DEFECT',
    BLOCKED: 'BLOCKED',
    EMPTY: 'EMPTY',
    RESERVED: 'RESERVED',
    INTERNAL_EVENT: 'INTERNAL_EVENT',
    TELAT: 'TELAT',
    WAITING: 'WAITING',
    APPOINTMENT: 'APPOINTMENT',
    DOKTER: 'DOKTER',
    POLI: 'POLI',
    ALLSELECT: 'ALLSELECT',
    HOLIDAY: 'HOLIDAY',
    IN_OPERATION: 'IN_OPERATION',
    LUNCHBREAK: 'LUNCHBREAK',
    OTHER_EVENT: "OTHER_EVENT",
    ANTRIAN: 'ANTRIAN',
    CLOSE_REGISTRATION: 'CLOSE_REGISTRATION',
    MAXIMAL_LOAD: process.env.VUE_APP_WEB_MAXIMAL_SLOT ? process.env.VUE_APP_WEB_MAXIMAL_SLOT : 500
}
