import Repository from './Repository';
import RepositoryAppointment from './RepositoryAppointment'
import paginateRepository from './AllPaginateRepository';
const reservation = "/reservasis";
const reservationv3 = '/v3/reservasis'
const reservationBackoffice3 = '/v3/reservasi-backoffice'
const reservationCancelled = '/v3/reservasis-cancelled'
const reservationBooking = "/reservasis-booking";
const reservationBookingNew = "/v3/reservasi-kalender";
// const reservationBackoffice = "/reservasis/backoffice";
const reservationBackoffice = "/v3/appointment-staff";
const reservasiOverride = '/v3/appointment-spv'
const resecheduleBackoffice = '/v3/reschedule-appointment-staff'
const resecheduleOverride = '/v3/reschedule-appointment-spv'
const cancelReservationv3 = '/v3/cancel-appointment-web'
const linkSearch = "/_search/reservasis";
const reservationById = "/reservasis-by-patient-id";
const cancelReservations = "/cancel-reservasis-by-ids"; //cancel 
const reservasiReschedule = "/reservasi-rescheduled";
const resecheduleReservation = '/reschedule-reservasis-by-id'
const refreshPayment = "/refresh-payment-status";
const recreatePayment = "/recreate-payment-link";
const reservasisByDoctor = "/reservasis-by-dokter";
const reservasisDasboard = "/v3/reservasis/dashboard";
// const reservasiOverride = 'reservasis/backoffice/override-tarif';
const linkReservasiDismiss = '/v3/appointment-dismiss'
const arriveAppointment = '/v3/appointment/arrive'
const updateMrnRegistrasi = '/v3/appointment/register'
import createRequestOption from './RequestParamUtil';

export default {
    get(req, resolve, reject , dokterParam) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(reservation, req, [], 0, resolve, reject , null , null , dokterParam);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    getDashboard(req, resolve, reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginateAppointmentWithCount(reservasisDasboard, req, [], 0, resolve, reject , null , null , null);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    getReservasisBooking(req, resolve, reject, dokterParam) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(reservationBooking, req, [], 0, resolve, reject, null, null, dokterParam);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    },
    async getReservasisBookingNew(req, resolve, reject , dokterParam) {
        let defaultConfigs =  createRequestOption(req);
        const statusIn = ["NEW" , "CONFIRM" , "ARRIVED"]
        if (dokterParam) {
            dokterParam.dokterIds.forEach(id => {
                defaultConfigs.params.append('dokterId' , id)
            })
        }
        statusIn.forEach(status => {
            defaultConfigs.params.append("status.in" , status)
        });
        const response = await RepositoryAppointment.get(reservationBookingNew , defaultConfigs)
        resolve(response)
    },
    updateNomorRegisDanMedAppointment(payload) {
        return RepositoryAppointment.post(`${updateMrnRegistrasi}`, payload)
    },
    updateNomerRegisDanMed(reservationId, payload) {
        return Repository.post(`reservasi/${reservationId}/nomor-registrasi-medical-record`, payload);
    },
    getReservasisDasboard(params, cancelToken) {
        return RepositoryAppointment.get(`${reservasisDasboard}`, { params: params, cancelToken })
    },
    search(req) {
        const defaultConfigs = createRequestOption(req);
        return Repository.get(`${linkSearch}`, defaultConfigs);
    },
    // getReservationTrans(params) {
    //     return Repository.get(`${reservation}`, params);
    // },
    // getReservationHistory(params) {
    //     return Repository.get(`${reservation}`, params);
    // },
    // getReservationConfirm(dateStart, dateEnd) {
    //     return Repository.get(`${reservation}`, { params: { "size": 1000, "status.in": "CONFIRM", "tanggalKunjungan.greaterOrEqualThan": dateStart, "tanggalKunjungan.lessOrEqualThan": dateEnd  } });
    // },
    getReservation(reservationId) {
        return Repository.get(`${reservation}/${reservationId}`);
    },
    getReservationAppointment(reservationId) {
        return RepositoryAppointment.get(`${reservationv3}/${reservationId}`)
    },
    getReservationAppointmentBackoffice(reservationId) {
        return RepositoryAppointment.get(`${reservationBackoffice3}/${reservationId}`)
    },
    createReservation(payload) {
        return Repository.post(`${reservation}`, payload);
    },
    createReservationById(payload) {
        return Repository.post(`${reservationById}`, payload);
    },
    updateReservation(payload) {
        return Repository.put(`${reservation}`, payload);
    },
    deleteReservation(reservationId) {
        return Repository.delete(`${reservation}/${reservationId}`);
    },
    cancelReservations(payload) {
        return Repository.post(`${cancelReservations}`, payload);
    },
    cancelReservationsV3 (generatedId) {
        return RepositoryAppointment.post(`${cancelReservationv3}`, {generatedId})
    },
    updateArriveAppointment(payload) {
        return RepositoryAppointment.put(`${arriveAppointment}`, payload)
    },
    updateArrived(reservationId, payload) {
        return Repository.put(`/reservasi/${reservationId}/status/arrived`, payload);
    },
    updateCanceled(reservationId) {
        return Repository.put(`/reservasi/${reservationId}/status/canceled`);
    },
    updateConfirmed(reservationId, payload) {
        return Repository.put(`/reservasi/${reservationId}/status/confirmed`, payload);
    },
    updateNomorRegistrasi(reservationId, payload) {
        return Repository.put(`/reservasi/${reservationId}/nomor-registrasi`, payload);
    },
    create(payload) {
        return RepositoryAppointment.post(`${reservationBackoffice}`, payload);
    },
    postRescheduleAppointment(payload) {
        return RepositoryAppointment.post(`${resecheduleBackoffice}`, payload);
    },
    getReservasisCancelled(params) {
        return RepositoryAppointment.get(`${reservationCancelled}`, {params});
    },
    createOverride(payload) {
        return RepositoryAppointment.post(`${reservasiOverride}`, payload);
    },
    postRescheduleOverride(payload) {
        return RepositoryAppointment.post(`${resecheduleOverride}`, payload);
    },
    update(payload) {
        return RepositoryAppointment.post(`${reservationBackoffice}`, payload);
    },
    reservasiReschedule(reservationId, payload) {
        return Repository.put(`${reservasiReschedule}/${reservationId}`, payload)
    },
    reservasiRescheduleWithCancel(id, payload) {
        return Repository.post(`${resecheduleReservation}/${id}`, payload)
    },
    reservasiRescheduleWithCancelOVerride(id, payload) {
        return Repository.post(`${resecheduleReservation}/${id}/override-tarif`, payload)
    },
    reservasiDismiss(generatedId) {
        return RepositoryAppointment.put(`${linkReservasiDismiss}/${generatedId}`)
    },
    refreshPaymentStatus(reservationId) {
        return Repository.put(`${refreshPayment}/${reservationId}`);
    },
    recreatePaymentLink(reservationId) {
        return Repository.put(`${recreatePayment}/${reservationId}`);
    },
    getReservasisDoctor(req, resolve, reject, dokterParam) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(reservasisByDoctor, req, [], 0, resolve, reject, null, null, dokterParam);
        }).then((response) => {
            resolve({ data: response });
        }).catch(error => {
            reject(error);
        });
    }
}