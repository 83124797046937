import Repository from './BaseRepository';
const paymentMethod = 'mandaya-payment/api/payment-methods'
const getPaymentTransactionUser = 'mandaya-payment/api/payment-transactions/user'
const paymentTransaction = 'mandaya-payment/api/payment-transactions'
const paymentClientMethods = 'mandaya-payment/api/client-payment-methods'
const paymentClientConfig = 'mandaya-payment/api/payment-configs'
const settingPaymentClient = 'mandaya-payment/api/client-payment-methods/settings'
const webhookList = '/mandaya-payment/api/payment-webhooks'
const createSnap = 'mandaya-payment/api/transaction/$userId/create-client-payment'
export default {
    getAllData(){
        return Repository.get(`${paymentMethod}`)
    },
    getIDPaymentMethod(id) {
        return Repository.get(`${paymentMethod}/${id}`)
    },
    postPaymentMethod(payload) {
        return Repository.post(`${paymentMethod}`, payload)
    },
    putPaymentMethod(payload) {
        return Repository.put(`${paymentMethod}`, payload)
    },
    getAllUserTransactionUser() {
        return Repository.get(`${getPaymentTransactionUser}`)
    },
    getTransactionChannels(userId) {
        const params = {
            'userId.equals' : userId
        }
        return Repository.get(`${paymentTransaction}`, {params})
    },
    getTransactionChannelsById(id){
        return Repository.get(`${paymentTransaction}/${id}`)
    },
    getPaymentMethodbyChannel(id) {
        const params ={
            'userId.equals': id
        }
        return Repository.get(`${paymentClientMethods}`, {params})
    },
    deletePaymentMethod(id) {
        return Repository.delete(`${paymentMethod}/${id}`)
    },
    getPaymentConfigsClient(id, state = false) {
        let params = {}
        if(!state) {
            params = {
                'userId.equals': id
            }
        } else {
            params = {
                'userId.specified': false
            }
        }
        return Repository.get(`${paymentClientConfig}`, {params})
    },
    postPaymentConfigClient(payload) {
        return Repository.post(`${paymentClientConfig}`, payload)
    },
    putPaymentConfigClient(payload) {
        return Repository.put(`${paymentClientConfig}`, payload)
    },
    deletePaymentConfig(id) {
        return Repository.delete(`${paymentClientConfig}/${id}`)
    },
    postPaymentSettingsClient(payload) {
        return Repository.post(`${settingPaymentClient}`, payload)
    },
    fetch(req) {
        const defaultConfigs = requestParams(req);
        return Repository.get(`${paymentTransaction}`, defaultConfigs);
    },
    fetchUser(req){
        const defaultConfigs = requestParams(req);
        return Repository.get(`${getPaymentTransactionUser}`, defaultConfigs)
    },
    fetchWebhookList(req){
        const defaultConfigs = requestParams(req);
        return Repository.get(`${webhookList}`, defaultConfigs)
    },
    postCreateSnap(data, userId){
        const url = createSnap.replace('$userId', userId)
        return Repository.post(url, data)
    }
}

function requestParams(req) {
    var options = new URLSearchParams();
    if( req ) {
        Object.keys(req).forEach(key => {
                options.set(key, req[key]);
        });
    }
    var request = {
        params: options
    }
    return request;
    }