import CheckPermission from "./checkPermission.service";
const checkPermission = new CheckPermission();
import qz from "qz-tray";
import INVALIDTYPE from "../constants/invalid-booking-list-enum";
class helpers {
    isHavePermission(method) {
        return checkPermission.isCanAccess(method);
    }
    convertSeperator(angka) {
        if (angka !== null) {
            const parts = angka.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            return parts.join(",");
        }
        return angka;
    }
    formatPhoneNumber(data) {
        if (data != null) {
            return data[0] == "6"
                ? "0" + data.substr(2, data.length - 1)
                : data;
        } else {
            return "";
        }
    }
    formatPhoneNumberNotZero(data) {
        if (data != null) {
            if (data[2] == "0") {
                var joinNumber = data.split("");
                joinNumber.splice(2, 1);
                return joinNumber.join("");
            }
            return data;
        } else {
            return "";
        }
    }
    filterUserTable(myObj) {
        var query = "";
        Object.keys(myObj).forEach(key => {
            query += key + ".keyword:*" + myObj[key] + "* ";
        });
        return query;
    }

    filterTablePatient (data, sort) {
        const params = new URLSearchParams()
        params.append('sort', sort)
        params.append('page', data.page)
        params.append('size', data.limit)
        if(data.gender) {
            data.query = {
                ...data.query,
                gender: data.gender
            }
        }
        if(data.query) {
           Object.keys(data.query).forEach((key) => {
                const keys = this.mapData(key)    
                params.append(keys, data.query[key])
            });
        }
        return params
    }

    mapData(id) {
        const DATA = {
            'agama.name': 'religion',
            'etnis.name': 'etnicity',
            'mobilePhone1': 'mobilePhone'
        }
        if(DATA[id]) {
            return DATA[id]
        }
        return id
        
    }


    filterTableNotif(myObj) {
        const objFilter = {
            ...(myObj. sendStatus ? { 'send_status.in': myObj.sendStatus} : {}),
            ...(myObj.recipient ? { "recipient.Contains": myObj.recipient }: {}),
            ...(myObj.id ? { "id.equals": myObj.id } : {}),       
        } 
        return objFilter
    }

    filterSMSTable(myObj) {
        const objFilter = {
            ...(myObj.created_at
                ? {
                      "created_at.greaterOrEqualThan": `${moment(
                          myObj.created_at.start
                      ).format("YYYY-MM-DD")}T00:00:00Z`
                  }
                : {}),
            ...(myObj.created_at
                ? {
                      "created_at.lessOrEqualThan": `${moment(
                          myObj.created_at.end
                      ).format("YYYY-MM-DD")}T23:59:00Z`
                  }
                : {}),
            ...(myObj.lastModifiedDate
                ? {
                      "lastModifiedDate.greaterOrEqualThan": `${moment(
                          myObj.lastModifiedDate.start
                      ).format("YYYY-MM-DD")}T00:00:00Z`
                  }
                : {}),
            ...(myObj.lastModifiedDate
                ? {
                      "lastModifiedDate.lessOrEqualThan": `${moment(
                          myObj.lastModifiedDate.end
                      ).format("YYYY-MM-DD")}T23:59:00Z`
                  }
                : {}),
            ...(myObj.id ? { "id.equals": myObj.id } : {}),
            ...(myObj.deliveryStatus
                ? { "deliveryStatus.in": myObj.deliveryStatus }
                : {}),
            ...(myObj.message ? { "message.contains": myObj.message } : {}),
            ...(myObj.recipient
                ? { "recipient.contains": myObj.recipient }
                : {}),
            ...(myObj.sendStatus ? { "sendStatus.in": myObj.sendStatus } : {}),
            ...(myObj.transactionId
                ? { "transactionId.contains": myObj.transactionId }
                : {}),
            ...(myObj.whatsappTemplateId ? { "whatsappTemplateId.equals": myObj.whatsappTemplateId } : {}),
            ...(myObj.fcmTemplateId ? { "fcmTemplateId.equals": myObj.fcmTemplateId } : {}),
            ...(myObj.emailTemplateId ? { "emailTemplateId.equals": myObj.emailTemplateId } : {}),
        };
        return objFilter;
    }
    filterTable(myObj) {
        var query = "";
        var pattern = '+-=&[]||><!(){}^"*#.,~?/\\: '
        Object.keys(myObj).forEach(key => {
            if (key == "tujuanRujukan") {
                let str = ''
                for (let i = 0; i < myObj[key].length; i++) {
                    const e = myObj[key][i];
                    if(pattern.includes(e)) str += '\\' + e
                    else str += e
                }
                const temp = key + '.nama.keyword' + ':*' + str + '*'
                query = query + '+' + temp + ' ';
            } else if (key == "jenisPenjamin") {
                query = query + "+jenisPenjamin.nama:*" + myObj[key] + "* ";
            } else  if (key == "jenisKelamin.name") {
                let str = ''
                for (let i = 0; i < myObj[key].length; i++) {
                    const e = myObj[key][i];
                    if(pattern.includes(e)) str += '\\' + e
                    else str += e
                }
                query = query + "+gender.name.keyword:*" + str + "* ";
            } else if(key == 'etnis.name'){
                query = query + "+etnicity.name:*" + myObj[key] + "* ";
            } else if(key == 'agama.name'){
                query = query + "+religion.name:*" + myObj[key] + "* ";
            } else if (key == "createdDateRange") {
                query =
                    query +
                    "+createdDate:[" +
                    moment(myObj[key].start).format("YYYY-MM-DD") +
                    " TO " +
                    moment(myObj[key].end).format("YYYY-MM-DD") +
                    "] ";
            } else if (key == "tanggalLahirRange") {
                query =
                    query +
                    "+tanggalLahir:[" +
                    moment(myObj[key].start).format("YYYY-MM-DD") +
                    " TO " +
                    moment(myObj[key].end).format("YYYY-MM-DD") +
                    "] ";
            } else if (key == "jejakTerakhirTimestampRange") {
                query =
                    query +
                    "+jejakTerakhirTimestamp:[" +
                    moment(myObj[key].start).format("YYYY-MM-DD") +
                    " TO " +
                    moment(myObj[key].end).format("YYYY-MM-DD") +
                    "] ";
            } else if (key == "tanggalKunjungan") {
                query =
                    query +
                    "+tanggalKunjungan:[" +
                    moment(myObj[key].start).format("YYYY-MM-DD") +
                    " TO " +
                    moment(myObj[key].end).format("YYYY-MM-DD") +
                    "] ";
            } else if (key == "perujukNama") {
                let arr = myObj[key].split(' ')
                let strings = '+('
                let fn = (arr.length)
                for (let i = 0; i < arr.length+1; i++) {
                    let first = ''
                    let last = ''
                    if(i !== 0) first += ' OR '
                    first += '('
                    arr.forEach((el, id) => {
                        let temp = ''
                        for (let i = 0; i < el.length; i++) {
                            const e = el[i];
                            if(pattern.includes(e)) temp += '\\' + e
                            else temp += e
                        }
                        if (id<fn) {
                            if(id == 0) first += 'perujuk.firstName.keyword:*'
                            if(id == (fn-1)) first += temp +  '*'
                            else first += temp + '\\ '
                        } else {
                            if(id == fn){
                                if(fn) first += ' AND '
                                first += `perujuk.lastName.keyword:*`
                            }
                            if(id == arr.length-1) last += temp + '*'
                            else last += temp + '\\ '
                        }
                    })
                    strings += first + last + ')'
                    fn--
                }
                strings += ')'
                query += strings ;
            } else if (key == "isPublished") {
                query = query + "+isPublished:" + myObj[key] + " ";
            } else if (key == "dateOfBirth") {
                query =
                    query +
                    "+dateOfBirth:[" +
                    moment(myObj[key].start).format("YYYY-MM-DD") +
                    " TO " +
                    moment(myObj[key].end).format("YYYY-MM-DD") +
                    "] ";
            } else {
                let str = ''
                for (let i = 0; i < myObj[key].length; i++) {
                    const e = myObj[key][i];
                    if(pattern.includes(e)) str += '\\' + e
                    else str += e
                }
                const temp = key + '.keyword' + ':*' + str + '*'
                query = query + '+' + temp + ' ';
            }
        });
        return query;
    }
    filterWithKey(myObj){
        let query = ''
        Object.keys(myObj).forEach((key,index) => {
            if(index == 0) {
                query = `${key}:*${myObj[key]}*`
            } else {
                query = `${query}+${key}:*${myObj[key]}*`
            }
        })
        return query
    }
    sortTable(myObj) {
        var isAsc = myObj.ascending ? "asc" : "desc";
        var column = "";
        if (myObj.orderBy == "tujuanRujukan") {
            column = "tujuanRujukan.nama";
        } else {
            column = myObj.orderBy;
        }
        return [column + "," + isAsc];
    }
    filterColor(myObj, column) {
        setTimeout(() => {
            column.forEach(value => {
                var div = $(".VueTables__" + value + "-filter-wrapper").closest(
                    "th"
                );
                if (
                    myObj.hasOwnProperty(value) &&
                    !this.isNullOrEmpty(myObj[value])
                ) {
                    div.addClass("bg-filtered-table");
                } else {
                    div.removeClass("bg-filtered-table");
                }
            });
        }, 200);
    }
    isNullOrEmpty(val) {
        return val == null || val == "";
    }
    arraySlots(mulai, selesai, durationSlot) {
        // disini balikin list jadwals yg siap di blok, akan kepake lagi di halaman utaman
        var date = moment(new Date()).format("YYYY-MM-DD");
        const totalSlot = this.countSlot(mulai, selesai, durationSlot);
        let counterSlot = 1;
        var startMoment = moment(`${date} ${mulai}`, "YYYY-MM-DD HH:mm");
        let tempJadwal = [];
        while (counterSlot <= totalSlot) {
            var start = startMoment.clone();
            var end = startMoment.add(durationSlot, "m");

            var objectSlot = {
                jamMulai: moment(start).format("HH:mm"),
                jamSelesai: moment(end).format("HH:mm")
            };
            tempJadwal.push(objectSlot);
            counterSlot++;
        }
        return tempJadwal;
    }
    countSlot(mulai, selesai, durationSlot) {
        const jamMulai = mulai.split(":");
        const jamSelesai = selesai.split(":");
        const minuteJamMulai =
            parseFloat(jamMulai[0] * 60) + parseFloat(jamMulai[1]);
        const minuteJamSelesai =
            parseFloat(jamSelesai[0] * 60) + parseFloat(jamSelesai[1]);
        const durationMinute = minuteJamSelesai - minuteJamMulai;
        return Math.floor(durationMinute / durationSlot);
    }
    durationSpelling(duration) {
        if (duration.asMinutes() < 60) {
            return duration.minutes() + " menit";
        } else if (duration.asHours() < 24) {
            var durationHours = duration.hours();
            var remainingDuration =
                duration.asSeconds() - durationHours * 60 * 60; //in seconds
            return (
                durationHours +
                " jam" +
                (remainingDuration <= 0
                    ? ""
                    : " " +
                      this.durationSpelling(
                          moment.duration(remainingDuration, "seconds")
                      ))
            );
        } else {
            var durationDays = Math.floor(duration.asDays());
            return `${durationDays} hari ${moment
                .utc(duration.asSeconds() * 1000)
                .format("HH [jam] mm [menit]")}`;
        }
    }
    objectFlip(obj) {
        const ret = {};
        Object.keys(obj).forEach(key => {
            ret[obj[key]] = key;
        });
        return ret;
    }
    splitingReservation(data, date) {
        const functMapping = listdata => {
            return listdata.map(y => {
                let reserv = { ...y };
                // reserv.queueId = y.queue.id;
                // reserv.jamMulai = y.jamMulai;
                // reserv.jamSelesai = y.jamSelesai;
                // reserv.nomorAntrian = y.queue.queueNoFormatted;
                // reserv.createdDate = y.queue.createdDate;
                // reserv.createdBy = y.queue.createdBy;
                // reserv.lastModifiedBy = y.queue.lastModifiedBy;
                // reserv.lastModifiedDate = y.queue.lastModifiedDate;
                return reserv;
            });
        };
        let reservasiWaiting = [];
        let reservasiAppointment = [];
        let reservasiDefect = [];
        let internalEvent = [];
        let listTelat = [];
        data.forEach(x => {
            // reservasi Appointment :
            const filterReservasiAppointment = x.slotJadwals.filter(y => {
                if (y.queue)
                    if (
                        y.slotType == "APPOINTMENT" &&
                        y.slotDefect == null
                        // y.queue.reservasi.tanggalKunjungan == date
                    )
                        return y;
            });
            const mapReservasiAppointment = functMapping(
                filterReservasiAppointment
            );
            reservasiAppointment = [
                ...reservasiAppointment,
                ...mapReservasiAppointment
            ];

            // reservasiDefect :
            
            const filterReservasiDefect = x.slotJadwals.filter(y => {
                if (
                    y.slotType == "APPOINTMENT" &&
                    y.slotDefect !== null 
                ) {
                    // y.queue.reservasi.jadwal = x.jadwal;
                    return y;
                }
            });
            const mapReservasiDefect = functMapping(filterReservasiDefect);
            reservasiDefect = [...reservasiDefect, ...mapReservasiDefect];
            // internalEvent
            const filterInternalEvent = x.slotJadwals.filter(y => {
                if (y.slotType == "INTERNAL_EVENT" && y.tanggal == date) {
                    //  y.DokterId = x.jadwal.dokter.id
                    y.jadwal = x.jadwal;
                    return y;
                }
            });
            internalEvent = [...internalEvent, ...filterInternalEvent];

            // TELAT
            const filterListTelat = x.slotJadwals.filter(y => {
                if (y.slotType == "TELAT" && y.tanggal == date) {
                    y.jadwal = x.jadwal;
                    return y;
                }
            });
            listTelat = [...listTelat, ...filterListTelat];

            // waitingList
            const filterWaitingList = x.queueWalkIns ? x.queueWalkIns.filter(y => {
                if (y.reservasi !== null && y.date == date) {
                    // console.log(y, 'yayooi');
                    y.jadwal = x.jadwal;
                    return y;
                }
            }) : [];
            reservasiWaiting = [...reservasiWaiting, ...filterWaitingList];
        });
        let hari = ["SUNDAY","MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
        let indexHari = new Date(date).getDay();
        let todayJadwal = [];
        data.forEach(y => {
            if (y.jadwal) {
                if (y.jadwal.hari == hari[indexHari])
                    todayJadwal.push(y.jadwal);
            }
        });
        return {
            reservasiWaiting,
            reservasiAppointment,
            reservasiDefect,
            internalEvent,
            listTelat,
            todayJadwal
        };
    }

    customMessage(payload) {
        let extras = JSON.parse(payload);
        const queueStatus = extras.queueStatus;

        switch (queueStatus) {
            case "SERVING":
                return "green";
            case "CALLING":
                return "blue";
            case "DONE":
                return "violet";
            case "SKIP":
                return "red";
            default:
                return "#506EE4";
        }
    }

    setColor(status) {
        switch (status) {
            case "SERVING":
                return "#506EE4";
            case "CALLING":
                return "#A7B6F1";
            case "DONE":
                return "#CCCCCC";
            case "CHECKEDIN":
                return "#A7B6F1";
            case "SKIPPED":
                return "#CCCCCC";
            case "NEW":
                return "#A7B6F1";
            case "CANCEL":
                return "#CCCCCC";
            case "DEFECT":
                return "#CCCCCC";
            default:
                return "#506EE4";
        }
    }

    setIconCaller(queueStatus) {
        switch (queueStatus) {
            case "SERVING":
                return "fas fa-user-clock";
            case "CALLING":
                return "fas fa-bullhorn";
            case "DONE":
                return "fas fa-user-check";
            case "SKIPPED":
                return "fas fa-user-times";
            case "CHECKEDIN":
                return "fas fa-user";
            case "NEW":
                return "far fa-user";
            case "CANCEL":
                return "fas fa-ban";
            case "DEFECT":
                return "fad fa-exclamation-triangle";
            default:
                return "fas fa-cloud";
        }
    }

    countAntrian(array) {
        const filterSlotJadwals = array.slotJadwals.filter(
            y =>
                y.queue.queueStatus == "CALLING" ||
                y.queue.queueStatus == "CHECKEDIN"
        );

        const filterQueue = array.queueWalkIns.filter(
            y => y.queueStatus == "CALLING" || y.queueStatus == "CHECKEDIN"
        );

        let jumlah = filterSlotJadwals.length + filterQueue.length;

        return jumlah;
    }

    setTime(list, date, jadwal) {
        const listNullTime = list.filter(
            x => x.estJamMulai == null && x.estJamSelesai == null
        );

        // Kalau ada yang null maka harus diisi waktu nya
        if (listNullTime.length > 0) {
            // ambil duration di jadwal
            const duration = 15;
            //cari starttime untuk list yang tidak null
            const listMomentTime = list
                .filter(x => x.estJamMulai !== null && x.estJamSelesai !== null)
                .map(y =>
                    moment(`${date} ${y.estJamSelesai}`, "YYYY-MM-DD HH:mm")
                );

            const startMoment =
                listMomentTime.length > 0
                    ? moment.max(listMomentTime)
                    : moment(`${date} ${jadwal.jamMulai}`, "YYYY-MM-DD HH:mm");
            // mari kita loop untuk memasukan start end time nya
            list.forEach(x => {
                if (x.estJamMulai == null && x.estJamSelesai == null) {
                    x.estJamMulai = startMoment.clone().format("HH:mm");
                    x.estJamSelesai = startMoment
                        .add(duration, "m")
                        .format("HH:mm");
                }
            });
            // Setelah loop lakukan put data dan set ulang di response walkin
            // const resp = await axios.put(responseWalkin) ....
            // responseWalkin = resp.data
        }
        return list;
    }

    checkPrinter() {
        const options = {
            host: "localhost",
            port: {
                insecure: [8182]
            },
            usingSecure: false
        };

        const result = {
            print: false,
            selectPrinter: false,
            defaultPrinter: null
        };
        const defaultPrinter = localStorage.defaultPrinter;
        return new Promise((resolve, reject) => {
            if (qz.websocket.isActive()) {
                if (defaultPrinter) {
                    qz.printers.find().then(data => {
                        if (data.includes(defaultPrinter)) {
                            result.print = true;
                            resolve(result);
                        } else {
                            result.selectPrinter = true;
                            resolve(result);
                        }
                    });
                } else {
                    result.selectPrinter = true;
                    resolve(result);
                }
            } else {
                qz.websocket
                    .connect(options)
                    .then(() => {
                        if (defaultPrinter) {
                            result.defaultPrinter = defaultPrinter;
                            result.print = true;
                            resolve(result);
                        } else {
                            result.selectPrinter = true;
                            resolve(result);
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });
            }
        });
    }

    daysToId(day) {
        switch (day) {
            case "SUNDAY":
                return "Minggu";
            case "MONDAY":
                return "Senin";
            case "TUESDAY":
                return "Selasa";
            case "WEDNESDAY":
                return "Rabu";
            case "THURSDAY":
                return "Kamis";
            case "FRIDAY":
                return "Jumat";
            case "SATURDAY":
                return "Sabtu";
            default:
                return "";
        }
    }

    checkShowDisplay({ start, end, formatDate }) {
        if (!start || !end) return true;
        const nowDate = moment.now();
        const startDate = moment(start, formatDate).format("x");
        const endDate = moment(end, formatDate).format("x");
        if (parseInt(startDate) <= nowDate && parseInt(endDate) >= nowDate)
            return true;
        return false;
    }

    cekRangeDate({
        searchStartDate,
        searchEndDate,
        dataStartDate,
        dataEndDate
    }) {
        if (!dataStartDate && !dataEndDate) return true;
        const defaultStartDate = "1970-01-01";
        const defaultEndDate = "2050-12-12";
        searchStartDate = searchStartDate ? searchStartDate : defaultStartDate;
        searchEndDate = searchEndDate ? searchEndDate : defaultEndDate;
        dataStartDate = dataStartDate ? dataStartDate : defaultStartDate;
        dataEndDate = dataEndDate ? dataEndDate : defaultEndDate;
        let momentSearchStartDate = moment(
            searchStartDate,
            "YYYY-MM-DD"
        ).format("x");
        let momentSearchEndDate = moment(searchEndDate, "YYYY-MM-DD").format(
            "x"
        );
        let momentDataStartDate = moment(dataStartDate, "YYYY-MM-DD").format(
            "x"
        );
        let momentDataEndDate = moment(dataEndDate, "YYYY-MM-DD").format("x");
        if (momentSearchStartDate <= momentDataStartDate) {
            if (momentSearchEndDate >= momentDataStartDate) {
                return true;
            }
        } else if (
            momentSearchStartDate >= momentDataStartDate &&
            momentSearchStartDate <= momentDataEndDate
        ) {
            return true;
        } else if (momentSearchEndDate <= momentDataStartDate) {
            return true;
        }
        return false;
    }
    mapperDefectStatus (data) {
        return new Promise((resolve) => {
          const allData = data.map(_item => {
            let statusInv = _item.defect ? INVALIDTYPE[_item.defect] : null
            return {
                ..._item,
                ...(statusInv ? {statusInv} : {}),
                ...(_item.status === 'CANCEL' ? {classCancel: _item.cancelStatus == 'NEED_CONFIRMATION' ? INVALIDTYPE.CLASS_CANCEL[0] : INVALIDTYPE.CLASS_CANCEL[1]} : {})
            }
          })
          resolve(allData)
        })
    }

    mapperInvalidBookingList(data) {
        return new Promise((resolve) => {
            data.forEach(el => {
                if(el.status === 'CANCEL') {
                    el.classCancel = el.cancelStatus === 'NEED_CONFIRMATION' ? INVALIDTYPE.CLASS_CANCEL[0] : INVALIDTYPE.CLASS_CANCEL[1]
                }
                else if (el.jadwal) {
                    if (el.jadwal.dokter.isDeleted) {
                        //apabila dokter sudah dihapus
                        el.statusInv = INVALIDTYPE.NODOKTER; //tipe invalid booking
                    } else if (el.jadwal.isDeleted) {
                        //apabila jadwal sudah dihapus
                        el.statusInv = INVALIDTYPE.DELETEJADWAL; //tipe invalid booking
                    } else if (el.defect) {
                        //apabila booking defect
                        const defectSlot = el.queue && el.queue.slotJadwal.slotDefect;
                        if (defectSlot) {
                            // el.statusInv = "noSlot";
                            const jamMulaiDefect = defectSlot.jamMulai;
                            const jamSelesaiDefect = defectSlot.jamSelesai;
                            const durationInMinute =
                                el.jadwal.durationInMinute;
                            const durationDefect =
                                moment(jamSelesaiDefect, "HH:mm:ss") -
                                moment(jamMulaiDefect, "HH:mm:ss");
                            const durationDefectInMinute =
                                durationDefect / 60000;

                            if (
                                durationDefectInMinute != durationInMinute
                            ) {
                                //pengecekkan apakah durasi booking dan durasi defect sama
                                el.statusInv =
                                    INVALIDTYPE.DIFFDURATION; //tipe invalid booking
                            }
                        }
                    } else if (
                        el.jadwal.tanggalMulai ||
                        el.jadwal.tanggalSelesai
                    ) {
                        const jadwalMulai = el.jadwal.tanggalMulai
                            ? el.jadwal.tanggalMulai
                            : "1900-01-01";
                        const jadwalSelesai = el.jadwal.tanggalSelesai
                            ? el.jadwal.tanggalSelesai
                            : "9999-01-01";
                        const tanggalKunjungan = el.tanggalKunjungan;
                        if (
                            !moment(tanggalKunjungan).isBetween(
                                moment(jadwalMulai),
                                moment(jadwalSelesai),
                                null,
                                "[]"
                            )
                        ) {
                            //apabila tanggal kunjungan berada di antara tanggal mulai dan tanggal selesai maka statusInv = "tanggalDiff"
                            el.statusInv = INVALIDTYPE.DIFFDATE; //tipe invalid booking
                        }
                    } else if (
                        el.jadwal &&
                        el.jadwal.statusJadwalDokters.length > 0
                    ) {
                        const statusJadwalDoktersArr =
                            el.jadwal.statusJadwalDokters;
                        const statusJadwalDokters = statusJadwalDoktersArr.find(
                            ele =>
                                ele.tanggal == el.tanggalKunjungan && // el dari forEach data
                                ele.status == "CANCELLED"
                        );
                        if (statusJadwalDokters) {
                            el.statusInv = INVALIDTYPE.DOKTERCUTI; //tipe dokter cuti
                        }
                    } else if (el.queue && !el.queue.slotJadwal) {
                        el.statusInv = INVALIDTYPE.NOSLOTJADWAL; //tipe invalid booking
                    }
                } else if (!el.jadwal) {
                    el.statusInv = INVALIDTYPE.NOJADWAL; //tipe invalid booking saat ngebuat data di host tenant
                }
            });
            resolve(data)
        })
    }

    mapperMasterPasien(data) {
        return data.map(_item => {
            return {
                ..._item,
                gender: _item.jenisKelamin && _item.jenisKelamin.initial ? _item.jenisKelamin.initial : '-'
            }
        })
    }
    selectHaveReservation(jamMulai, jamSelesai, responseQueue) {
        if(!jamMulai || !jamSelesai) return false
        let isSelected = false
        for(let i = 0; i < responseQueue.length; i++) {
            if(responseQueue[i].slotJadwals.length == 0) continue
            const slotPerson = responseQueue[i].jadwal.personInSlot
            const selected = []
            responseQueue[i].slotJadwals.forEach(_item => {
                if(_item.jamMulai == jamMulai && _item.jamSelesai == jamSelesai){
                    selected.push('reserved')
                }
            })
            if(selected.length > 0 && selected.length >= slotPerson) {
                isSelected = true
                break
            }
        }
        if(isSelected) return true
        return false
    }
}
export default (helpers = new helpers());
